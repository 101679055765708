<template>
  <div>
    <div>
      <ConnectWallet />
    </div>

    <div class="z-0 mp-10">
      <div
        class="bg-cover h-screen w-screen bg-center flex items-center justify-center"
        style="
          background-image: url('https://cdn.discordapp.com/attachments/1080058777971212370/1118839426114650134/Ethan_Pic_03.png');
        "
      >
        <div class="text-center">
          <h2
            class="max-w-lg mb-6 font-sans text-xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto text-white"
          >
            @ethan_lee
          </h2>

          <h2
            class="font-sans text-3xl font-bold sm:text-7xl md:mx-auto text-white"
          >
            Ethan Lee
          </h2>
        </div>
      </div>

      <div class="w-full bg-green-500">
        <div class="grid grid-cols-1 gap-2 lg:grid-cols-2">
          <div class="flex items-center justify-center">
            <div class="p-4">
              <h2
                class="max-w-2xl text-xl font-bold tracking-tight sm:text-3xl md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-blue-700 to-red-500 mt-10"
              >
                Meet Ethan Lee, a 22-years-old man with a passion for art and
                design. Born on March 3, 2001, in Bangkok, Thailand, Ethan is of
                mixed heritage, with a Thai mother and a Chinese father. He has
                grown up in a creative and vibrant environment, with a love for
                art and design from a young age.
              </h2>
              <br />

              <h2
                class="max-w-2xl font-sans text-lg tracking-tight sm:text-2xl md:mx-auto text-white"
              >
                At 5'9" tall and weighing 150 pounds, Ethan has a lean and
                artistic build. He loves to stay inspired by exploring the
                city's galleries and museums, as well as traveling to other
                countries to experience different cultures and artistic
                traditions.
              </h2>

              <br />

              <div class="text-center">
                <hr class="h-px my-4 border-blue-600 border" />

                <h2
                  class="max-w-2xl font-sans text-lg leading-none tracking-tight sm:text-2xl md:mx-auto text-gray-200"
                >
                  Age : 22 years old
                </h2>
                <hr class="h-px my-4 border-blue-600 border" />

                <h2
                  class="max-w-2xl font-sans text-lg leading-none tracking-tight sm:text-2xl md:mx-auto text-gray-200"
                >
                  Blood Type : O
                </h2>
                <hr class="h-px my-4 border-blue-600 border" />

                <h2
                  class="max-w-2xl font-sans text-lg leading-none tracking-tight sm:text-2xl md:mx-auto text-gray-200"
                >
                  Hight : 180 cm. , Weight : 68 kg.
                </h2>
                <hr class="h-px my-4 border-blue-600 border" />

                <h2
                  class="max-w-2xl font-sans text-lg leading-none tracking-tight sm:text-2xl md:mx-auto text-gray-200"
                >
                  Chest : 44” , Waist : 30” , Hips : 39”
                </h2>

                <hr class="h-px my-4 border-blue-600 border" />
              </div>
            </div>
          </div>
          <div class="flex justify-center">
            <img
              src="https://cdn.discordapp.com/attachments/1080058777971212370/1118838816875221062/Ethan_Pic_02.png"
              class="h-96 lg:h-screen"
            />
          </div>
        </div>
      </div>

      <div class="w-full bg-black pt-24 pb-24">
        <div
          class="mx-auto sm:max-w-2xl lg:max-w-screen-lg md:px-24 lg:px-8 text-white p-4"
        >
          <div class="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <div>
              <div class="grid grid-cols-2 gap-1 lg:grid-cols-2">
                <div>
                  <img
                    src="https://cdn.discordapp.com/attachments/1080058777971212370/1112669089199046687/00014-3335510175.png"
                    class="h-40 w-40 lg:w-56 lg:h-56 rounded-full object-cover ease-in-out"
                  />
                </div>
                <div class="h-full w-full flex items-center">
                  <div>
                    <h2
                      class="max-w-2xl font-sans text-lg tracking-tight sm:text-2xl md:mx-auto text-white"
                    >
                      @nisa.zhang
                    </h2>

                    <h2
                      class="max-w-2xl font-sans text-4xl tracking-tight md:mx-auto text-white mt-3"
                    >
                      50K <b class="text-sm">followers </b>
                    </h2>
                    <a
                      href="https://www.instagram.com/nisa.zhang/"
                      target="_blank"
                      class="inline-block p-px bg-gradient-to-t from-gray-300 via-green-600 to-gray-300 rounded-xl mt-5"
                    >
                      <div
                        class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded-xl text-gray-200 bg-black cursor-pointer hover:bg-gray-200 hover:text-gray-900"
                      >
                        Follow
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="h-full w-full flex items-center">
              <div>
                <h2
                  class="text-lg tracking-tight sm:text-xl md:mx-auto text-white mt-6"
                >
                  Ethan is a specialist in the field of graphic design and is
                  currently pursuing a degree in Visual Communication Design at
                  Silpakorn University in Bangkok. His creativity and attention
                  to detail have already earned him recognition from top design
                  firms, and he has worked on several high-profile branding and
                  advertising campaigns. 💖🎶🌈☔️🍕🍷⚽️✈️🎡📷
                </h2>

                <div class="mt-5">
                  <div class="flex">
                    <a class="m-2 cursor-pointer" href="" target="_blank">
                      <i class="fab fa-twitter" style="font-size: 40px"></i>
                    </a>

                    <a class="m-2 cursor-pointer" href="" target="_blank">
                      <i class="fab fa-reddit" style="font-size: 40px"></i>
                    </a>

                    <a class="m-2 cursor-pointer" href="" target="_blank">
                      <i class="fab fa-youtube" style="font-size: 40px"></i>
                    </a>

                    <a class="m-2 cursor-pointer" href="" target="_blank">
                      <i class="fab fa-instagram" style="font-size: 40px"></i>
                    </a>

                    <a class="m-2 cursor-pointer" href="" target="_blank">
                      <i class="fab fa-facebook" style="font-size: 40px"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="bg-blue-400 w-full flex justify-center items-center pt-40 pb-48"
      >
        <div class="text-center">
          <h2
            class="font-sans text-3xl font-bold sm:text-6xl md:mx-auto text-white"
          >
            Ethan Lee
          </h2>
          <h2
            class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto text-white"
          >
            @ethan_lee
          </h2>
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="-mt-48 flex justify-center">
          <img
            src="https://cdn.discordapp.com/attachments/1080058777971212370/1118838780330266664/Ethan_Pic_01.png"
            class="inset-0 object-cover ease-in-out"
          />
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="">
          <hr class="h-px my-4 bg-gray-500 border-0" />

          <h2
            class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto"
          >
            VIRTUAL INFLUENCERS
          </h2>
          <hr class="h-px my-4 bg-gray-500 border-0" />
        </div>

        <div class="grid grid-cols-1 gap-2 lg:grid-cols-2 md:grid-cols-2">
          <div>
            <div
              class="transition duration-300 ease-in-out transform-gpu hover:-translate-y-1 cursor-pointer rounded-sm rounded"
              style="margin-top: 10px"
              v-for="s in models"
              :key="s"
            >
              <a>
                <img :src="s.img" alt="Image 1" class="h-full w-full" />
              </a>
            </div>
          </div>

          <div>
            <div
              class="transition duration-300 ease-in-out transform-gpu hover:-translate-y-1 cursor-pointer rounded-sm rounded"
              style="margin-top: 10px"
              v-for="s in models_2"
              :key="s"
            >
              <a>
                <img :src="s.img" alt="Image 1" class="h-full w-full" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";

export default {
  data() {
    return {
      models: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112669089882718239/00048-2890323437.png",
          name: "Nisa Zhang",
          nickname: "nisa_zhang",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112669090146942986/00050-2718298115.png",
          name: "FN Meka",
          nickname: "fnmeka",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112669090725773373/00082-2325294244.png",
          name: "Lu of Magalu",
          nickname: "magazineluiza",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112669088897040455/00009-3335510173.png",
          name: "Barbie",
          nickname: "barbie",
        },
      ],
      models_2: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112669089199046687/00014-3335510175.png",
          name: "Any Malu",
          nickname: "anymalu_real",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112669089547157524/00026-4172047435.png",
          name: "CB of Casas Bahia",
          nickname: "casasbahia",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112669089882718239/00048-2890323437.png",
          name: "Miquela Sousa",
          nickname: "lilmiquela",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112669090725773373/00082-2325294244.png",
          name: "Hatsune Miku",
          nickname: "cfm_miku_official",
        },
      ],
    };
  },
  components: {
    ConnectWallet,
  },
  methods: {
    linkDetail(s) {
      if (s.price == "free") {
        return "/promptFree/" + s._id;
      } else {
        return "/prompt/" + s._id;
      }
    },
  },
  created() {},
};
</script>
